// extracted by mini-css-extract-plugin
export var article = "post-module--article--zB89e";
export var articleSubtitle = "post-module--articleSubtitle--a1EEK";
export var articleWindow = "post-module--articleWindow--U8LID";
export var authorDate = "post-module--authorDate--VGztT";
export var authorImage = "post-module--authorImage--A3GQp";
export var authorName = "post-module--authorName--sQeUo";
export var body = "post-module--body--wqA2m";
export var date = "post-module--date--TD-EL";
export var hero = "post-module--hero--Uf5ci";
export var iconsPanel = "post-module--iconsPanel--woOtP";
export var postData = "post-module--postData--zrwnY";
export var shareRow = "post-module--shareRow--x+w7I";
export var socialIcon = "post-module--socialIcon--0FPig";
export var title = "post-module--title--00Z2E";